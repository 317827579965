import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import EditRetirementFundGoalPlanRetirementAgeService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-retirement-age-service';
import EditRetirementFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-amount-service';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import { FinalInvestmentDateCalculatorDto }
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';
import {
  InvestorGoalContributionDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import { StateManager }
  from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import {
  StateManager as RecurringContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditRetirementFundNewFundViewModel {
  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
  private readonly retirement_age_service!: EditRetirementFundGoalPlanRetirementAgeService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditRetirementFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditRetirementFundGoalPlanDefineByService;

  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly allianz_account_state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  readonly recurring_contributions_state_manager!: RecurringContributionsStateManager;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.new_fund';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('current_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('monthly_contribution'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('monthly_pension'),
      current_plan: '',
      new_plan: '',
    },
  ];

  initial_investment_date = '';

  final_investment_date = '';

  is_loading = false;

  is_checkbox_active = false;

  home_desire = false;

  monthly_required_amount = 0;

  limit_per_contribution = 50000;

  maximum_amount_exceeded = true;

  contributions_to_the_limit = true;

  recurrent_contributions_state = this.recurring_contributions_state_manager.state;

  initialize = async () => {
    this.loadRecurrentContributionInformation();
    await this.setInitialValues();
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBorderClass(index: number) {
    return (index < 3) ? '' : 'rounded-br-lg';
  }

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  isIndexLessThanZero(index: number) {
    return index > 0;
  }

  setInitialValues = async () => {
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    const define_by = this.getDefineByInformation();
    const amount_to_reach_goal = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.contribution : define_by.by_pension.you_must_save;
    const desired_monthly_pension = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.you_must_save : define_by.by_pension.contribution;
    const retirement_range_adjusted = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.retirement_range_adjusted : define_by.by_pension.retirement_range_adjusted;
    let initial_amount_adjusted = this.parseCurrencyToNumber(current_plan.current_amount);
    if (current_amount.there_is_more_savings === 'yes') {
      initial_amount_adjusted += current_amount.amount_added;
    }
    const new_target_amount = (define_by.is_defined_by_contribution) ? define_by.by_contribution
      .accumulated_amount : define_by.by_pension.accumulated_amount;
    this.monthly_required_amount = amount_to_reach_goal;
    this.plan_information[0].current_plan = current_plan.target_amount;
    this.plan_information[0].new_plan = this.getAmountFormatted(new_target_amount);
    this.plan_information[1].current_plan = current_plan.current_amount;
    this.plan_information[1].new_plan = this.getAmountFormatted(initial_amount_adjusted);
    this.plan_information[2].current_plan = current_plan.monthly_required_amount;
    this.plan_information[2].new_plan = this.getAmountFormatted(amount_to_reach_goal);
    [this.plan_information[3].current_plan] = current_plan.monthly_pension.split('mensuales');
    this.plan_information[3].new_plan = this.getAmountFormatted(desired_monthly_pension);
    await this.loadNewFinalInvestmentDate(retirement_range_adjusted);
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  loadNewFinalInvestmentDate = async (retirement_range_adjusted: number) => {
    try {
      this.initial_investment_date = this.datetime_value.create().toString();
      const payload: FinalInvestmentDateCalculatorDto = {
        initial_investment_date: this.initial_investment_date,
        period_in_months: retirement_range_adjusted,
      };
      const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
      this.final_investment_date = final_investment_date;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_final_investment_date_query'));
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  updateContributions = (amount: number) => {
    const contributions: Array<InvestorGoalContributionDto> = [];
    this.recurrent_contributions_state.item.contributions.forEach((contribution) => {
      contributions.push({
        id: contribution.id,
        collection_day: contribution.collection_day,
        amount,
      });
    });
    return contributions;
  }

  updateContributionGoBackToOne = (amount: number) => {
    const { retirement_fund_id } = this.getCurrentPlanInformation();
    const contributions: Array<InvestorGoalContributionDto> = [];
    let id_contribution_to_update = -1;
    let maximum_total_remaining = 0;
    this.recurrent_contributions_state.item.contributions.forEach(
      (contribution, index) => {
        contributions.push({
          id: contribution.id,
          collection_day: contribution.collection_day,
          amount: 0,
        });
        const remaining_distributions = contribution.distributions.filter(
          (distribution) => distribution.investor_goal_id !== retirement_fund_id,
        );
        const total_remaining = remaining_distributions.reduce(
          (sum, d) => sum + d.recurrent_assigned_amount, 0,
        );
        if (total_remaining > maximum_total_remaining
          && total_remaining <= this.limit_per_contribution) {
          maximum_total_remaining = total_remaining;
          id_contribution_to_update = index;
        }
      },
    );
    contributions[id_contribution_to_update].amount = amount;
    return contributions;
  }

  validateGoBackToOneContribution = (new_amount: number) => (
    this.recurrent_contributions_state.item.contributions.length > 1 && this.is_checkbox_active
    && new_amount < this.limit_per_contribution
  )

  validateUpToTwoContribution = (new_amount: number) => (
    this.is_checkbox_active && new_amount > this.limit_per_contribution
  );

  calculateNewContributionAmount = () => {
    const { retirement_fund_id } = this.getCurrentPlanInformation();
    let goal_recurrent_assigned_amount = 0;
    this.recurrent_contributions_state.item.contributions.forEach((contribution) => {
      contribution.distributions.forEach((distribution) => {
        if (distribution.investor_goal_id === retirement_fund_id) {
          goal_recurrent_assigned_amount += distribution.recurrent_assigned_amount;
        }
      });
    });
    const amount_without_distribution = this.recurrent_contributions_state.item
      .total_contribution - goal_recurrent_assigned_amount;
    return amount_without_distribution + this.monthly_required_amount;
  }

  updateNewRetirementFund = async () => {
    try {
      this.is_loading = true;
      let contributions: Array<InvestorGoalContributionDto> = [];
      const new_amount = this.calculateNewContributionAmount();
      const define_by = this.getDefineByInformation();
      define_by.initial_investment_date = this.initial_investment_date;
      define_by.final_investment_date = this.final_investment_date;
      define_by.modify_recurring_contribution_automatically = this.is_checkbox_active;
      define_by.go_back_to_one_contribution = this.validateGoBackToOneContribution(new_amount);
      define_by.maximum_amount_exceeded = this.contributions_to_the_limit
        && !this.is_checkbox_active;
      this.define_by_service.setDefineByInformation(define_by);
      if (this.validateUpToTwoContribution(new_amount)) {
        this.view.$emit('nextStep');
      } else {
        if (define_by.go_back_to_one_contribution) {
          contributions = this.updateContributionGoBackToOne(
            this.monthly_required_amount,
          );
        } else {
          contributions = this.updateContributions(
            this.monthly_required_amount,
          );
        }
        this.view.$emit('updatePlan', contributions);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_plan_retirement_fund_command'));
    } finally {
      this.is_loading = false;
    }
  }

  validateIfShowUpdateContribution = () => {
    const new_amount = this.calculateNewContributionAmount();
    this.maximum_amount_exceeded = new_amount > 100000;
  }

  validateIfIsOnLimitAmount = () => {
    this.contributions_to_the_limit = this.recurrent_contributions_state.item
      .total_contribution >= this.limit_per_contribution * 2;
  }

  loadRecurrentContributionInformation = () => {
    const { home_desire } = this.allianz_account_state_manager.state.account;
    this.home_desire = home_desire;
    this.validateIfShowUpdateContribution();
    this.validateIfIsOnLimitAmount();
  }
}
