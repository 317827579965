





















































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditRetirementFundNewFundViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-new-fund-view-model';

@Component({ name: 'EditRetirementFundNewFund' })
export default class EditRetirementFundNewFund extends Vue {
  edit_retirement_fund_new_fund_view_model = Vue.observable(
    new EditRetirementFundNewFundViewModel(this),
  );

  async mounted() {
    await this.edit_retirement_fund_new_fund_view_model.initialize();
  }
}
